import React, {useEffect, useState} from "react";
import api from "../../api";
import {checkAnyOfPermissions, createScopeLink} from "../../helpers";
import {Button, message, Statistic} from "antd";
import {DeleteOutlined, EditOutlined} from "@ant-design/icons";
import {DataCard} from "../helpers/cards/Card";
import {StandardTable} from "../helpers/TablePanel";
import {AddScopeModal} from "./AddScopeModal";
import Search from "antd/es/input/Search";

export function ScopesList({projectId, activeKey}) {


    const [isAdmin, setIsAdmin] = useState(false);
    const [isSuperAdmin, setIsSuperAdmin] = useState(false);
    const [startUpdate, setStartUpdate] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [createdByFilterOptions, setCreatedByFilterOptions] = useState([])
    const [scopeTypeOptions, setScopeTypeOptions] = useState([])
    const [selectedScope, setSelectedScope] = useState(null)


    const style = {
        height: '80vh', maxHeight: '80vh'
    }



    useEffect(() => {
        checkAnyOfPermissions(['senior-user', 'approve-user', 'insurance-admin', "admin", 'material-analyst'])
            .then(() => setIsAdmin(true))
        checkAnyOfPermissions(['superadmin'])
            .then(() => setIsSuperAdmin(true))

        getOptionsForFilter('user', setCreatedByFilterOptions)
        getOptionsForFilter('type', setScopeTypeOptions)
    }, [projectId])

    const getOptionsForFilter = (fieldName, setFilterOptions) => {
        if (projectId) {
            api.get(`/projects/${projectId}/scopes/filter/options?field_name=${encodeURI(fieldName)}`)
                .then(json => setFilterOptions(json.data))
        }
    }

    const columns = [
        {
            title: 'Scope Name',
            dataIndex: 'name',
            key: 'name',
            render: (name, item) => <a href={createScopeLink(projectId, item._id)}>{name}</a>
        }, {
            title: 'Scope Description',
            dataIndex: 'description',
            key: 'description',
        }, {
            title: 'Created By',
            dataIndex: 'user',
            key: 'user',
            filters: createdByFilterOptions.map(option => {
                return {
                    text: option,
                    value: option,
                }
            })
        }, {
            title: 'Items in Scope',
            dataIndex: 'quantity',
            key: 'quantity',
            render: quantity => <Statistic value={quantity} valueStyle={{fontSize: 14}}/>
        }, {
            title: 'Actions',
            dataIndex: '_id',
            key: '_id',
            render: (_id, item) => (item.type === 'main-scope' ? isSuperAdmin : isAdmin) && <div>
                <EditOutlined className={'beautiful-link'} onClick={() => editScope(item)}/>
            </div>
        }, {
            dataIndex: '_id',
            key: '_id',
            render: (_id, item) => (item.type === 'main-scope' ? isSuperAdmin : isAdmin) && <div>
                <DeleteOutlined className={'beautiful-link'}  onClick={() => deleteScope(_id)}/>
            </div>
        },
    ]

    const deleteScope = _id => {
        api.get(`/projects/${projectId}/scopes/delete?id=${_id}`)
            .then(() => {
                message.success(`The scope was successfully deleted`)
                setStartUpdate(!startUpdate)
            })
            .catch(() => message.error('Something went wrong during deletion! Please try again later.'))
    }

    const editScope = scope => {
        setIsModalVisible(true)
        setSelectedScope(scope)


    }


    return <DataCard style={style}>
        {isAdmin && <div style={{paddingBottom: 10, float: 'right'}}>
            <Button onClick={() => {
                setIsModalVisible(true)
            }}>Add new scope</Button>
        </div>}
        <ScopesTable columns={columns} projectId={projectId} isModalVisible={isModalVisible} activeKey={activeKey}
                     update={startUpdate}/>

        <AddScopeModal isSuperAdmin={isSuperAdmin} isModalVisible={isModalVisible} scope={selectedScope} setSelectedScope={setSelectedScope} setIsModalVisible={setIsModalVisible}
                       projectId={projectId}/>
    </DataCard>

}

export function ScopesTable({columns, projectId, isModalVisible, activeKey, update}) {

    const [scopes, setScopes] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0);
    const [filters, setFilters] = useState({})
    const [query, setQuery] = useState('')

    const getScopes = (_page = page, size = pageSize, _filters = filters, _query = query) => {
        setLoading(true)
        if (size !== pageSize || _filters !== filters || query !== _query) {
            _page = 1
        }
        api.post(`/projects/${projectId}/scopes?page=${_page}&size=${size}`, {_filters, _query})
            .then(json => {
                setScopes(json.data.items)
                setTotal(json.data.total)
                setPage(_page)
                setQuery(_query)
                setFilters(_filters)
                setPageSize(size)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (projectId) {
            getScopes(1)
        }
    }, [projectId, isModalVisible, activeKey, update])

    const onChange = (newPagination, _filters, _sorter) => {
        getScopes(newPagination.current, newPagination.pageSize, _filters)
    }

    return <div>
        <div style={{marginBottom: 10}}>
            <Search enterButton="Search" onSearch={value => getScopes(
                page, pageSize, filters, value
            )} />
        </div>
        {query && <div style={{marginBottom: 5}}>
            Current Query: <b>{query}</b>
        </div>}
        <StandardTable size={'small'}
                       loading={loading}
                       style={{width: '100%',}}
                       total={total}
                       currentPage={page}
                       columns={columns}
                       scroll={500}
                       onChange={onChange}
                       pageSize={pageSize}
                       tableProps={{
                           rowClassName: (record) => record.type === 'main-scope' ? 'main-scope' : ''
                       }}
                       data={scopes}/>
    </div>
}
