import React, {Component, useEffect, useState} from "react";
import {CustomTabs} from "../helpers/CustomTabs";
import {DataCard} from "../helpers/cards/Card";
import {StandardTable} from "../helpers/TablePanel";
import {Button, Card, Col, Form, Input, message, Row, Select, Spin, Tag} from "antd";
import api from "../../api";
import {createReportOverviewLink, createScopeLink, getProjectId} from "../../helpers";
import {DeleteOutlined, EditOutlined, PlusOutlined} from "@ant-design/icons";
import {useForm} from "antd/es/form/Form";
import {ModalWindow} from "../helpers/modals/ModalWindow";
import TextArea from "antd/es/input/TextArea";
import {AdvancedFilter} from "../helpers/AdvancedFIlter";
import {labels} from "../scopes/ItemsListTable";
import {StandardForm} from "../helpers/forms/StandardForm";
import {Link} from "../helpers/cards/MaterialBlock";

const {Option} = Select;


class CreateReportTab extends Component {

    constructor(props) {
        super(props);
        this.state = {
            template_name: [],
            user: [],
            update: false,
            selectedReport: null,
            isModalVisible: false

        }
    }

    componentDidMount() {
        this.updateFilters()
    }

    updateFilters = () => {
        this.getOptionsForFilter('template_name')
        this.getOptionsForFilter('user')
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.projectId !== this.props.projectId) {
            this.updateFilters()
        }

        if (prevProps.activeKey !== this.props.activeKey) {
            this.updateFilters()
        }

        if (prevState.isModalVisible !== this.state.isModalVisible && !this.state.isModalVisible) {
            this.updateFilters()
        }
    }

    getOptionsForFilter = (fieldName) => {
        if (this.props.projectId) {
            api.get(`/projects/${this.props.projectId}/exception-reporting/templates/filter/options?field_name=${encodeURI(fieldName)}`)
                .then(json => this.setState({[fieldName]: json.data}))
        }
    }


    deleteTemplate = (_id) => {
        api.delete(`/projects/${this.props.projectId}/exception-reporting/templates?template_id=${_id}`)
            .then(json => {
                message.success('Template was deleted!')
                this.setState({update: !this.state.update})
            })
    }

    editTemplate = report => {
        this.setState({
            selectedReport: report,
            isModalVisible: true
        })
    }


    columns = () => [
        {
            title: 'Template Name',
            dataIndex: 'template_name',
            key: 'template_name',
            filters: this.state.template_name.map(option => {
                return {
                    text: option,
                    value: option,
                }
            })
        }, {
            title: 'Template Description',
            dataIndex: 'template_description',
            key: 'description',
        }, {
            title: 'Created By',
            dataIndex: 'user',
            key: 'user',
            filters: this.state.user.map(option => {
                return {
                    text: option,
                    value: option,
                }
            })
        }, {
            title: 'Actions',
            dataIndex: '_id',
            key: '_id',
            render: (_id, item) => <div>
                <DeleteOutlined className={'beautiful-link'} onClick={() => this.deleteTemplate(_id)}/>
            </div>
        },{
            title: '',
            dataIndex: '_id',
            key: '_id',
            render: (_id, item) => <div>
                <EditOutlined className={'beautiful-link'} onClick={() => this.editTemplate(item)}/>
            </div>
        }
    ]

    onSave = values => {
        const isUpdate = this.state.selectedReport !== null
        api.post(`/projects/${this.props.projectId}/exception-reporting/templates/save?is_update=${isUpdate}`, values)
            .then(json => {
                message.success('Report was saved!')
                this.setState({isModalVisible: false, selectedReport: null, update: !this.state.update})
            })
    }

    render() {
        return <div>

            <Button style={{float: 'right', marginBottom: 10}} onClick={() => this.setState({isModalVisible: true})}>Add
                New Exception Report</Button>
            <TemplatesTable columns={this.columns()} projectId={this.props.projectId}
                            url={`/projects/${this.props.projectId}/exception-reporting/templates?`}
                            isModalVisible={this.state.isModalVisible} update={this.state.update}/>

            {this.state.isModalVisible && <CreateTemplateModal isModalVisible={this.state.isModalVisible}
                                                               onSave={this.onSave}
                                                               selectedReport={this.state.selectedReport}
                                                               projectId={this.props.projectId}
                                                               onCancel={() => this.setState({isModalVisible: false})}
            />}

        </div>
    }

}


function CreateTemplateModal({isModalVisible, onSave, onCancel, projectId, selectedReport}) {

    const [form] = useForm()

    const [filters, setFilters] = useState([])
    const [newConditionsCardColor, setNewConditionsCardColor] = useState("error")
    const [conditions, setConditions] = useState([])
    const [addConditionIsActive, setAddConditionIsActive] = useState(false)

    const saveForm = () => {
        form.submit()
    }

    useEffect(() => {
        if (selectedReport !== null) {
            form.setFieldsValue({
                template_name: selectedReport.template_name,
                template_description: selectedReport.template_description,
            })
            setConditions(selectedReport['conditions'])
        }

    }, [selectedReport]);

    const onOk = (values) => {
        let payload = {...values, conditions: conditions}
        if (selectedReport !== null) {
            payload['_id'] = selectedReport['_id']
        }
        onSave(payload)
    }

    const typeLabels = {
        error: 'Error',
        warning: 'Warning',
        good: 'Good'
    }

    const deleteFilter = (attr) => {
        let filts = [...filters]
        filts = filts.filter(x => !(x.key === attr.key && x.value === attr.value && x.mode === attr.mode))
        setFilters(filts)
    }

    const addNewCondition = (field) => {
        let message = form.getFieldValue('conditions')
        setConditions([
            ...conditions, {
                'message': message[0].message,
                'type': message[0].type || 'error',
                filters: [...filters]
            }
        ])
        form.resetFields(['conditions'])
        form.resetFields(['conditions.filters'])
        setFilters([])
        setAddConditionIsActive(false)
    }

    return <ModalWindow isModalVisible={isModalVisible}
                        onOk={saveForm}
                        modalProps={{width: '60vw'}}
                        onCancel={onCancel}
                        title={'Create new template for exception reporting\n'}>
        <Form form={form} onFinish={onOk} autoComplete="off">
            <Form.Item
                name="template_name"
                label="Template Name"
                rules={[
                    {
                        required: true,
                        message: 'Missing template name!',
                    },
                ]}
            >
                <Input/>
            </Form.Item>

            <Form.Item
                name="template_description"
                label="Template Description"
                rules={[
                    {
                        required: true,
                        message: 'Missing template description!',
                    },
                ]}
            >
                <TextArea/>
            </Form.Item>
            <Row>
                {conditions.map(value => {
                    return <Col span={12} style={{padding: 10}}><Card className={`${value.type}-card`}>
                        <div>Message: <ExceptionStatusTag condition={value}/>
                            <div className={'beautiful-link'}
                                 onClick={() => setConditions(conditions.filter(_c => _c !== value))}
                                 style={{float: "right"}}>
                                <DeleteOutlined/>
                            </div>
                        </div>
                        <div style={{marginTop: 10}}>
                            Filters: <FilterRepresentation filters={value.filters} labels={labels}
                                                           deleteFilter={() => null}/>
                        </div>
                    </Card></Col>
                })}
            </Row>

            <Form.List name="conditions">
                {(fields, {add, remove}) => (
                    <>
                        {fields.map((field) => (

                            <Card className={`${newConditionsCardColor}-card`} style={{padding: 10, margin: 10}}>

                                <div style={{marginBottom: 10}}>
                                    <AdvancedFilter filters={filters}
                                                    labels={labels}
                                                    setFilter={setFilters}
                                                    projectId={projectId}/>
                                </div>
                                <div style={{marginBottom: 10}}>
                                    Filters: <FilterRepresentation filters={filters} labels={labels}
                                                                   deleteFilter={deleteFilter}/>
                                </div>

                                <Form.Item
                                    {...field}
                                    label="Message"
                                    name={[field.name, 'message']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Missing message!',
                                        },
                                    ]}
                                >
                                    <TextArea/>
                                </Form.Item>

                                <Form.Item
                                    {...field}
                                    label="Type"
                                    name={[field.name, 'type']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Missing Type!',
                                        },
                                    ]}
                                >
                                    <Select style={{width: 200}} defaultValue={"error"}
                                            onChange={value => setNewConditionsCardColor(value)}>
                                        {['error', 'warning', 'good'].map((opt, key) => <Option key={key}
                                                                                                value={opt}>{typeLabels[opt]}</Option>)}
                                    </Select>
                                </Form.Item>

                                <div style={{float: 'right'}}>

                                    <Button style={{marginRight: 5}} onClick={() => {
                                        remove(field.name)
                                        setAddConditionIsActive(false)
                                    }}>
                                        Remove Condition
                                    </Button><Button onClick={() => addNewCondition(field)}>
                                    Save Condition
                                </Button>
                                </div>
                            </Card>
                        ))}

                        {!addConditionIsActive && <Form.Item style={{marginTop: 10}}>
                            <Button type="dashed" onClick={() => {
                                add()
                                setAddConditionIsActive(true)
                            }} block icon={<PlusOutlined/>}>
                                Add Condition
                            </Button>
                        </Form.Item>}
                    </>
                )}
            </Form.List>

        </Form>
    </ModalWindow>

}


export function ExceptionStatusTag({condition}) {

    const getColor = () => {
        if (condition.type === 'good') {
            return 'green'
        }

        return condition.type
    }


    return <Tag color={getColor()}>{condition.message}</Tag>
}


function RunExceptionRepostModal({isModalVisible, onSave, onCancel, projectId, loading}) {

    const [form] = useForm()

    const [scopes, setScopes] = useState([])
    const [templates, setTemplates] = useState([])

    const saveForm = () => {
        form.submit()
    }

    useEffect(() => {
        api.get(`/projects/${projectId}/iterations/scopes`).then(json => setScopes(json.data.items))
        api.get(`/projects/${projectId}/exception-reporting/templates`).then(json => setTemplates(json.data.items))

    }, [])

    const onOk = (values) => {
        values['template_name'] = templates.find(_s => _s._id === values['template_id'])['template_name']
        values['scope_name'] = scopes.find(_s => _s._id === values['scope_id'])['name']
        onSave(values)
    }

    const getScopeOptions = () => scopes.map(_scope => {
        return {
            label: `${_scope.name} (${_scope.quantity})`,
            value: _scope._id
        }
    })

    const getTemplatesOptions = () => templates.map(_template => {
        return {
            label: `${_template.template_name}`,
            value: _template._id
        }
    })


    const getValuesFields = () => [
        {label: 'Report Name', name: 'report_name', _type: 'text', required: true},
        {
            label: 'Template Name',
            name: 'template_id',
            _type: 'select-object',
            required: true,
            options: getTemplatesOptions()
        },
        {label: 'Scope Name', name: 'scope_id', _type: 'select-object', required: true, options: getScopeOptions()},
    ]

    return <ModalWindow isModalVisible={isModalVisible}
                        onOk={saveForm}
                        modalProps={{width: '80%'}}
                        onCancel={onCancel}
                        title={'Run exception reporting\n'}>

        <Spin spinning={loading}>
            <StandardForm form={form} onFinish={onOk} fields={getValuesFields()}/>
        </Spin>

    </ModalWindow>

}


export function FilterRepresentation({deleteFilter, labels, filters, breakLines = false}) {

    const modeLabels = mode => {
        switch (mode) {
            case 'eq':
                return 'Equal to'
            case 'gt':
                return 'Greater than'
            case 'lt':
                return 'Less than'
            case 'full':
                return 'Full Search'
            case 'partial':
                return 'Partial Search'
            case 'in':
                return 'Include'
            case 'not_include':
                return 'Not Include'

            default:
                return mode
        }
    }

    if (!filters) {
        return null
    }

    return filters.map(attr => {
        if (!breakLines) {
            return <Tag className={`${attr.mode}-search`}
                        style={{cursor: 'pointer'}}
                        onClick={() => deleteFilter(attr)}>
                [<b><i>{modeLabels(attr.mode)}</i></b>] {labels[attr.key] || attr.key}: <b>{attr.value}</b>
            </Tag>
        } else {
            return <Row style={{paddingTop: 5}}>
                <Tag className={`${attr.mode}-search`}
                     style={{cursor: 'pointer'}}
                     onClick={() => deleteFilter(attr)}>
                    [<b><i>{modeLabels(attr.mode)}</i></b>] {labels[attr.key] || attr.key}: <b>{attr.value}</b>
                </Tag>
            </Row>
        }

    })

}

export function TemplatesTable({columns, projectId, isModalVisible, activeKey, update, url, setTotalCount}) {

    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10)
    const [total, setTotal] = useState(0);
    const [filters, setFilters] = useState({})


    const getTemplates = (_page = page, size = pageSize, _filters = filters) => {
        setLoading(true)
        if (size !== pageSize || _filters !== filters) {
            _page = 1
        }
        api.post(`${url}page=${_page}&size=${size}`, {_filters})
            .then(json => {
                setTemplates(json.data.items)
                setTotal(json.data.total)
                setPage(_page)
                setFilters(_filters)
                setPageSize(size)
                setTotalCount && setTotalCount(json.data.total)
            })
            .finally(() => setLoading(false))
    }

    useEffect(() => {
        if (projectId) {
            getTemplates(1)
        }
    }, [projectId, isModalVisible, activeKey, update])

    const onChange = (newPagination, _filters, _sorter) => {
        getTemplates(newPagination.current, newPagination.pageSize, _filters)
    }

    return <div>
        <StandardTable size={'small'}
                       loading={loading}
                       style={{width: '100%',}}
                       total={total}
                       currentPage={page}
                       columns={columns}
                       scroll={500}
                       onChange={onChange}
                       pageSize={pageSize}
                       data={templates}/>
    </div>
}

export class ExceptionReporting extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeKey: 'run-report',
            projectId: getProjectId(props.location)
        }
    }

    getTabs = () => [{
        name: 'Run Report',
        key: 'run-report',
        children: <RunReport projectId={this.state.projectId} activeKey={this.state.activeKey}/>
    }, {
        name: 'Create Report',
        key: 'create-report',
        children: <CreateReportTab projectId={this.state.projectId} activeKey={this.state.activeKey}/>
    }]


    render() {
        return <DataCard style={{margin: 5, height: 'calc(100vh - 75px)'}}>
            <CustomTabs activeKey={this.state.activeKey}
                        defaultKey={this.state.activeKey}
                        tabsData={this.getTabs()}
                        onChange={activeKey => this.setState({activeKey: activeKey})}/>

        </DataCard>
    }

}

class RunReport extends Component {
    constructor(props) {
        super(props);
        this.state = {
            template_name: [],
            user: [],
            scope_name: [],
            loading: false,
            update: false,
            isModalVisible: false

        }
    }

    updateFilters = () => {
        this.getOptionsForFilter('template_name')
        this.getOptionsForFilter('scope_name')
        this.getOptionsForFilter('user')
    }

    componentDidMount() {
        this.updateFilters()
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.projectId !== this.props.projectId) {
            this.updateFilters()
        }


        if (prevState.isModalVisible !== this.state.isModalVisible && !this.state.isModalVisible) {
            this.updateFilters()
        }
    }

    getOptionsForFilter = (fieldName) => {
        if (this.props.projectId) {
            api.get(`/projects/${this.props.projectId}/exception-reporting/reports/filter/options?field_name=${encodeURI(fieldName)}`)
                .then(json => this.setState({[fieldName]: json.data}))
        }
    }

    deleteReport = (projectId, reportId) => {
        api.delete(`/projects/${projectId}/exception-reporting/${reportId}`)
            .then(json => {
                message.success('Report was deleted!')
                this.setState({update: !this.state.update})
            })
    }


    columns = () => [
        {
            title: 'Report Name',
            dataIndex: 'report_name',
            key: 'report_name',
        }, {
            title: 'Template Name',
            dataIndex: 'template_name',
            key: 'template_name',
            filters: this.state.template_name.map(option => {
                return {
                    text: option,
                    value: option,
                }
            })
        }, {
            title: 'Scope Name',
            dataIndex: 'scope_name',
            key: 'scope_name',
            filters: this.state.scope_name.map(option => {
                return {
                    text: option,
                    value: option,
                }
            }),
            render: (scope_name, report) => <div className={'beautiful-link'} onClick={() => {
                window.open(createScopeLink(this.props.projectId, report.scope_id))
            }}>{scope_name}</div>
        }, {
            title: 'Created By',
            dataIndex: 'user',
            key: 'user',
            filters: this.state.user.map(option => {
                return {
                    text: option,
                    value: option,
                }
            })
        }, {
            title: 'Actions',
            dataIndex: '_id',
            key: '_id',
            width: 100,
            render: (_id, item) => <Link className={'beautiful-link'}
                                         href={createReportOverviewLink(this.props.projectId, _id)}>
                Open
            </Link>
        },{
            title: '',
            dataIndex: '_id',
            key: '_id',
            render: (_id) => <div className={'beautiful-link'}
                                         onClick={() => this.deleteReport(this.props.projectId, _id)}>
                <DeleteOutlined />
            </div>
        },
    ]

    onSave = values => {
        this.setState({loading: true})
        api.post(`/projects/${this.props.projectId}/exception-reporting/reports/save`, values)
            .then(json => {
                message.success('Report was created!')
                this.setState({isModalVisible: false})
            }).finally(() => this.setState({loading: false}))
    }

    render() {
        return <div>

            <Button style={{float: 'right', marginBottom: 10}} onClick={() => this.setState({isModalVisible: true})}>Run
                Exception Report</Button>
            <TemplatesTable columns={this.columns()} projectId={this.props.projectId} update={this.state.update}
                            url={`/projects/${this.props.projectId}/exception-reporting/reports?`}
                            isModalVisible={this.state.isModalVisible}/>

            {this.state.isModalVisible && <RunExceptionRepostModal isModalVisible={this.state.isModalVisible}
                                                                   onSave={this.onSave}
                                                                   loading={this.state.loading}
                                                                   projectId={this.props.projectId}
                                                                   onCancel={() => this.setState({isModalVisible: false})}
            />}

        </div>
    }
}
