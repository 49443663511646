import React from 'react';
import {DataCard} from "../helpers/cards/Card";
import {Button, Descriptions, message} from "antd";
import * as Auth from "../../AuthService";
import axios from "../../api";
import {downloadExcel} from "../../helpers";
import api from "../../api";

export function LeadTimeReport() {

    const projectId = window.location.pathname.split('/')[2]

    const download = () => {
        const config = Auth.createConfig();
        config['responseType'] = 'blob';
        console.log(config)
        api.get(`/projects/${projectId}/lead-time/export`, config)
            .then(json => {
                console.log(1)
                downloadExcel(json.data, `Lead Time report.xlsx`)
            }).catch(error => {
                message.error(error)
        })
    }

    return <DataCard title="Lead Time Report" style={{margin:10}}>
        <div>
            <Descriptions title="Used constants">
                <Descriptions.Item label="Date period">Last 5 years</Descriptions.Item>
            </Descriptions>
        </div>

        <Button onClick={download} >Download the report</Button>
    </DataCard>

}
