import {useForm} from "antd/es/form/Form";
import api from "../../api";
import {Button, Form, message, Steps} from "antd";
import {getDescription, getIcon} from "./WorkflowVisualization";
import TextArea from "antd/es/input/TextArea";
import React, {useEffect, useState} from "react";

export function TasksViewer ({current, userTasks, onApprove, userInfo, projectId, itemInfo}) {

    const [form] = useForm()
    const [currentStep, setCurrentStep] = useState([])

    useEffect(() => {
        setCurrentStep(prepare())
        form.resetFields()
    }, [current, userTasks])

    const approveStep = (task) => {
        message.info('Start approving the step')
        const data = {
            'action': task.step_type,
            user: userInfo['email'],
            position: task.position,
            status: 'done',
        }
        doAction(data, task)
    }

    const rejectStep = (task) => {
        message.info('Start rejecting the step')
        const data = {
            'action': "reject",

        }
        doAction(data, task)
    }

    const doAction = (data, task) => {
        const taskData = {
            task_id: task.task_id,
            step: task.step,
            workflow_id: task.workflow_id,
            object: task.object,
            object_type: task.object_type,
            email_cc: [],
            project_id: projectId,
            material_object_id: itemInfo['_id'],
            comment: form.getFieldValue('comment')
        }

        api.post(`/tasks/actions`, {...taskData, ...data })
            .then(json => {
                message.success(json.data)
                onApprove()
            })
            .catch(error => {
                onApprove()
            })
    }

    const cancelStep = (task) => {
        message.info('Start canceling the workflow')
        const data = {
            'action': "cancel",
        }
        doAction(data, task)
    }

    const checkIfCanApproveSingle = (task) => {
        let tasksForUser = userTasks.filter(t => (t.step_type !== 'acknowledge') && (t.status === 'active'))

        return !!tasksForUser.find(t => (t.task_id === task.task_id) && (t.step === task.step) && (t.user === task.user))
    }

    const arrayEquals = (a, b) => {
        return Array.isArray(a) &&
            Array.isArray(b) &&
            a.length === b.length &&
            a.every((val, index) => val === b[index]);
    }

    const checkIfCanApproveMultiple = (task) => {
        let tasksForUser = userTasks.filter(t => (t.step_type !== 'acknowledge') && (t.status === 'active'))
        return !!tasksForUser.find(t => (t.task_id === task.task_id) && (t.step === task.step) && (arrayEquals(task.user, t.user)))
    }

    const getUserDescription = (task) => {
        if (typeof task.user === 'string') {

            const canApprove = checkIfCanApproveSingle(task)

            return <p style={{color: 'black'}}>{getDescription(task)} {canApprove &&
                <div>
                    <Form form={form}>
                        <Form.Item label={'Input the comment'} name={'comment'}>
                            <TextArea />
                        </Form.Item>
                    </Form>
                    <Button onClick={() => approveStep(task)}>Approve</Button>

                    {task.step === 0
                        ? <Button style={{marginLeft: 10}} onClick={() => cancelStep(task)}>Cancel</Button>
                        : <Button style={{marginLeft: 10}} onClick={() => rejectStep(task)}>Reject</Button>
                    }
                </div>}
            </p>
        } else if (Array.isArray(task.user)) {
            const canApprove = checkIfCanApproveMultiple(task)

            return <p style={{color: 'black'}}>Could be approved by any of the users in position:
                <div><b>{task.position}</b>:</div> {task.user.map(u => <div>{u}</div>)}

                {canApprove &&
                <div>
                    <Form form={form}>
                        <Form.Item label={'Input the comment'} name={'comment'}>
                            <TextArea />
                        </Form.Item>
                    </Form>
                    <Button onClick={() => approveStep(task)}>Approve</Button>

                    {task.step === 0
                        ? <Button style={{marginLeft: 10}} onClick={() => cancelStep(task)}>Cancel</Button>
                        : <Button style={{marginLeft: 10}} onClick={() => rejectStep(task)}>Reject</Button>
                    }
                </div>}
            </p>
        }
    }

    const prepare = () => {
        if (current) {

            return current.map(task => {
                if (task.step_type === 'acknowledge') {
                    return null;
                }



                return {
                    icon: getIcon(task.step_type, task.step + 1),
                    description: getUserDescription(task)
                }})
        }
    }

    return <Steps
        direction="vertical"
        items={currentStep}
        current={currentStep.length}
    />
}
