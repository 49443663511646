import FileDownload from "js-file-download";
import {DEFAULT_OPERATOR, projectCurrency, properties} from "./properties";
import {Button, Input} from "antd";
import {SearchOutlined} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import React from "react";
import {MaterialBlock} from "./components/helpers/cards/MaterialBlock";
import api from "./api";

export const getProjectId = pathname => {
    return pathname.pathname.split('/')[2]
}

export const downloadExcel = (data, filename) => {
    FileDownload(data, filename, filename);
}

export const groupBy = (arr, key) => {
    return arr.reduce((dict, item) => {
        (dict[item[key]] = dict[item[key]] || []).push(item);
        return dict;
    }, {});
};

export const capitalizeFirstLetter = (s) => {
    return s[0].toUpperCase() + s.slice(1)
}

export const createIHLink = (operator, material) => {
    return `${properties.ihLink}/data?operator=${operator}&material=${material}`
}

export const createDMLink = (operator, material) => {
    return `${properties.dmLink}/dm?search=${material}&batch=material-mapped-batch`
}

export const createKHLink = (operator, material) => {
    return `${properties.khLink}&q=${material}`
}

export const createEHLink = (operator, material, type='material') => {
    return `${properties.assetHubLink}/entity_links?type=${type}&item=${material}`
}

export const createMINMAxLink = (material, project_id, from) => {
    return `/projects/${project_id}/min-max-material-analysis?material=${material}&from=${from}`
}

export const createInsuranceLink = (material, project_id, from) => {
    return `/projects/${project_id}/ins-calculation-material-analysis?material=${material}&from=${from}`
}

export const createScenarioLink = (project_id, scenario_id) => {
    return `/projects/${project_id}/min-max/scenarios/${scenario_id}/overview`
}

export const createScopeLink = (project_id, scope_id) => {
    return `/projects/${project_id}/scope/${scope_id}`
}

export const createWorkflowLink = (project_id, workflow_id) => {
    return `/projects/${project_id}/workflow-settings/${workflow_id}`
}

export const createReportOverviewLink = (project_id, report_id) => {
    return `/projects/${project_id}/exception-reporting/${report_id}`
}

export const createDashboardLink = (projectId, category) => {
    return `/projects/${projectId}/dashboard-v2/${category}`
}


export const openLinkInNewTab = href => {
    window.open(href)
}

export const openHalfLink = (href, projectId) => {
    window.open(`/projects/${projectId}/${href}`, '_self')
}

export const openLink = (href) => {
    window.open(href, '_self')
}

let searchInput;

export const getColumnSearchProps = (dataIndex, addLink, state, handleReset, handleSearch) => (

    {
    filterDropdown: ({
                         setSelectedKeys,
                         selectedKeys,
                         confirm,
                         clearFilters
                     }) => (
        <div className="custom-filter-dropdown">
            <Input
                ref={node => {
                    searchInput = node;
                }}
                placeholder={`Search ${dataIndex}`}
                value={selectedKeys[0]}
                onChange={e =>
                    setSelectedKeys(e.target.value ? [e.target.value] : [])
                }
                onPressEnter={() => handleSearch(selectedKeys, dataIndex, confirm)}
                style={{ width: 188, marginBottom: 8, display: "block" }}
            />
            <Button
                type="primary"
                onClick={() => handleSearch(selectedKeys, dataIndex, confirm)}
                size="small"
                style={{ width: 90, marginRight: 8 }}
            >
                <SearchOutlined />
                Search
            </Button>
            <Button
                onClick={() => handleReset(clearFilters)}
                size="small"
                style={{ width: 90 }}
            >
                Reset
            </Button>
        </div>
    ),
    filterIcon: filtered => (
        <SearchOutlined style={{ color: filtered ? "#1890ff" : undefined }} />
    ),
    onFilterDropdownVisibleChange: visible => {
        if (visible) {
            setTimeout(() => searchInput.select());
        }
    },
    render: (text, item) => (addLink ? <MaterialBlock material={text}>
    <Highlighter
        style={{color: addLink ? properties.kbrColors.pantone301Blue : 'black', cursor: addLink ? 'pointer' : ''}}
        onClick={() => addLink ? openLinkInNewTab(createIHLink(item.operator || DEFAULT_OPERATOR, text)) : null}
        highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
        searchWords={[state.searchText]}
        autoEscape
        textToHighlight={text ? text.toString() : null}
    />
</MaterialBlock> : <div>
                    <Highlighter
                        style={{color: addLink ? properties.kbrColors.pantone301Blue : 'black', cursor: addLink ? 'pointer' : ''}}
                        onClick={() => addLink ? openLinkInNewTab(createIHLink(item.operator ||DEFAULT_OPERATOR, text)) : null}
                        highlightStyle={{backgroundColor: "#ffc069", padding: 0}}
                        searchWords={[state.searchText]}
                        autoEscape
                        textToHighlight={text ? text.toString() : null}
                    />
                </div>
    )
});


export const labelMapper = (field) => {

    const dictionary = {
        capital_cost: 'Capital Cost (%)',
        holding: 'Holding (%)',
        shelf_years: 'Shelf Years (years)',
        running_hours: 'Running Hours',
        production_output: 'Production Output',
        loss: 'Loss (%)',
        unitsPerDay: 'UNITS / DAY',
        costPerUnit: 'COST / UNIT',
        holding_cost: 'Holding Cost',
        stockout_cost: 'Stockout Cost',
        writeoff_cost: 'Writeoff Cost',
        interest_cost: 'Interest Cost',
        warehouse_cost: 'Warehouse Cost',
        total_cost: 'Total Cost',
        part_availability: 'Part Availability',
        shell_life: 'Shelf Life (years)',
        demand_quantity: 'Demand Quantity',
        demand_interval: 'Demand Interval (Years)',
        'Unit$': `Part Cost (${projectCurrency})`,
        'price': `Part Cost (${projectCurrency})`,
        installed: 'Installed Quantity',
        required: 'Required Quantity',
        equipments: 'Where used',
        equipment: 'Where used',
        criticality: 'Equipment Criticality',
        ratio: 'PM:CM Ratio',
        'not_important': 'Increase/Decrease',
        lt:"Decrease",
        gt: "Increase",
        scope: 'Attached Scope',
        high: 'High',
        any: 'Any',
        low: 'Low'
    }

    return dictionary[field] || field

}

export const getURLParam = param => {
    let p = window.location.search;
    const params = new URLSearchParams(p);
    return params.get(param) || ''
}

export const sumByField = (arr, path) => {
    return arr.reduce( function(obj1, obj2){
        let value = {...obj2}
        for (let p of path) {
            value = value[p]
        }
        return obj1 + value;
    }, 0);
}

export const checkAllPermissions = (permissions) => api.post('/check_permission', {permissions, mode: 'all'})


export const checkAnyOfPermissions = (permissions) => api.post('/check_permission', {permissions, mode: 'any'})
