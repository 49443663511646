import React from "react";
import api from "../../api";
import {Button, Col, Descriptions, Dropdown, Menu, message, Row} from "antd";
import {DataCard} from "../helpers/cards/Card";
import {ROP_MIN_LABEL} from "../../properties";
import {MaterialBlock} from "../helpers/cards/MaterialBlock";
import {getProjectId} from "../../helpers";
import {TasksViewer} from "./TasksViewer";


export class WorkflowTodo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            tasks: [],
            user: {},
            projectId: getProjectId(props.location)
        }
    }

    getUserTasks = () => {
        api.get(`/tasks/user/${this.state.user['email']}?active=True`,)
            .then(json => {
                this.setState({tasks: json.data})
                console.log(json.data[0])
            })
    }

    getUser = () => {
        api.get('/get_current_user').then(json => this.setState({user: json.data}))
    }

    componentDidMount() {
        this.getUser()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.user !== this.state.user) {
            this.getUserTasks()
        }
    }

    approveStep = (task) => {
        message.info('Start approving the step')
        const data = {
            'action': task.step_type,
            user: this.state.user['email'],
            position: task.position,
            status: 'done',
        }
        this.doAction(data, task)
    }

    rejectStep = (task) => {
        message.info('Start rejecting the step')
        const data = {
            'action': "reject",

        }
        this.doAction(data, task)
    }

    doAction = (data, task) => {
        const taskData = {
            task_id: task.task_id,
            step: task.step,
            workflow_id: task.workflow_id,
            object: task.object,
            object_type: task.object_type,
            email_cc: [],
            project_id: this.state.projectId,
            // comment: form.getFieldValue('comment')
        }

        api.post(`/tasks/actions`, {...taskData, ...data })
            .then(json => {
                message.success(json.data)
                this.getUserTasks()
            })
            .catch(error => {
                this.getUserTasks()
            })
    }

    cancelStep = (task) => {
        message.info('Start canceling the workflow')
        const data = {
            'action': "cancel",
        }
        this.doAction(data, task)
    }


    onStepEnd = () => {
        message.info('Action completed successfully!')
        this.getUserTasks()
    }


    render() {
        return <>
            <Row>
                <Col span={24} style={{textAlign: "center", padding: 10}}>
                    <DataCard>
                        <h3>Your active tasks {this.state.tasks && <>({this.state.tasks.length})</>}</h3>
                    </DataCard>
                </Col>
            </Row>
            <Row>
                {this.state.tasks.map((task, i) => {


                    const menu = (
                        <Menu>
                            <Menu.Item onClick={() => this.approveStep(task)}>Approve</Menu.Item>
                            {task.step === 0 && <Menu.Item onClick={() => this.cancelStep(task)}>Cancel</Menu.Item>}
                            {task.step !== 0 && <Menu.Item onClick={() => this.rejectStep(task)}>Reject</Menu.Item>}
                        </Menu>
                    );


                    return <Col span={12} key={i} style={{padding: 10}}>
                        <DataCard title={<><MaterialBlock material={task.object}>Step {task.step + 1}: {task.object}</MaterialBlock></>}>
                            <Descriptions>
                                <Descriptions.Item label={ROP_MIN_LABEL}>{task.min}</Descriptions.Item>
                                <Descriptions.Item label={'MAX'}>{task.max}</Descriptions.Item>
                            </Descriptions>
                            {/*<Row>*/}
                            {/*    <Col span={24}>*/}
                            {/*        <Dropdown overlay={menu}>*/}
                            {/*            <Button style={{float: 'right'}} type={'primary'}>Actions</Button>*/}
                            {/*        </Dropdown>*/}
                            {/*    </Col>*/}
                            {/*</Row>*/}
                            <TasksViewer onApprove={this.onStepEnd} current={[task]}
                                         userInfo={this.state.user}
                                         projectId={this.state.projectId}
                                         itemInfo={{_id: null}}
                                         userTasks={this.state.tasks}/>
                        </DataCard>

                    </Col>
                })}
            </Row>

        </>

    }

}
