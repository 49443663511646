import React, {useEffect} from 'react';
import {ModalWindow} from "./ModalWindow";
import {StandardForm} from "../forms/StandardForm";
import {Form} from "antd";

export function StandardModalForm ({save, isModalVisible, closeModal, initialValues, fields}) {

    const handleCancel = () => {
        closeModal();
    };

    const onFinish = (values) => {
        save(values)
    };

    const [form] = Form.useForm()

    useEffect(() => {
        if (isModalVisible) {
            fields.map((field, i) => {
                form.setFieldsValue({[field.name]: ''})
            })
            form.setFieldsValue({...initialValues})
        } else {
            form.resetFields()
        }
    }, [isModalVisible])

    return <ModalWindow width={900} title={`Edit data`}
                        isModalVisible={isModalVisible}
                        scrollVisible={false}
                        onCancel={handleCancel}
                        modalProps={{
                            okText: "Update",
                            cancelText: "Cancel",
                            okButtonProps: {form: 'editForm', key: 'submit', htmlType: 'submit'}
                        }}>
        {isModalVisible ? <StandardForm form={form} initialValues={{...initialValues}} onFinish={onFinish} fields={fields}/> : null}
    </ModalWindow>

}
